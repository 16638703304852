import React , {useContext} from 'react'
import {Button, Container, Col, Row, Accordion, Card, useAccordionToggle, Carousel, AccordionContext} from 'react-bootstrap';


// import VandA from '../img/V_and_A_Museum.png'
// import Olimpic from '../img/olympic-park-logo.png'



const Clients = () => (
  <Row>

    <Col xs={2}>
      <p>Selected Clents: </p>
    </Col>

    <Col xs={8}>

        <Carousel controls={false} interval={2000} indicators={false} pause={false} wrap={true} style={{height: '20px'}}>
        
        {/* TO Map This one out!! */}

          <Carousel.Item >
            <Container>
                <div className='d-flex flex-fill align-items-center'>
                    <div className='d-flex '>
                    {/* <img className='mr-5' src={VandA} style={{ maxWidth: '2.5rem', height: 'auto' }}/> */}
                    </div>
                    <div className='d-flex'>
                    The Victoria and Albert Museum London
                    </div>
                </div>
            </Container>
          </Carousel.Item>

          <Carousel.Item >
            <Container>
                <div className='d-flex flex-fill align-items-center'>
                    <div className='d-flex '>
                    {/* <img className='mr-5' src={VandA} style={{ maxWidth: '2.5rem', height: 'auto' }}/> */}
                    </div>
                    <div className='d-flex'>
                    Queen Elizabeth Olimpic Park 
                    </div>
                </div>
            </Container>
        
        
          </Carousel.Item>
        </Carousel>

    </Col>

    <Col xs={2} className='text-right'>
      <FooterAcordionButton/>
    </Col>

  </Row>
)


export default Clients


// Acordion Toggle Button
const FooterAcordionButton = () => {
    return(
      <FooterOpen eventKey="0"></FooterOpen>
    )
  }
  

  function FooterOpen({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
      const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
    const isCurrentEventKey = currentEventKey === eventKey;
    return (
      <Button
        type="button"
        onClick={decoratedOnClick}
        className=  {` ${isCurrentEventKey ?  'x-dropdown' : 'arrow-up-dropdown'} `}
      > 
        {children}
      </Button>
    );
  }
  