import React from 'react'
import { Container, Row, Col, Accordion, Card, useAccordionToggle, Button} from 'react-bootstrap'
import { graphql, useStaticQuery } from "gatsby"
import { Link } from 'gatsby'
import { kebabCase } from 'lodash'
import SearchIcon from '../img/search.svg' 
import EmailIcon from '../img/email.svg' 

const TopAccordion = (props) => {

  const data = useStaticQuery(graphql`
     query TagsAccordionQuery {
         allMarkdownRemark(limit: 1000) {
            group(field: frontmatter___tags) {
             fieldValue
           }
         }
       }
      `
  )

return (
<Accordion.Collapse eventKey="0">
<Card.Body className='px-0 py-0'>

<AccordionClose>
  <Container fluid className={`${props.navbarBackroundColor} py-3`}>
          <Row className='topnav'>
            <Col xs={6} sm={6} md={4} lg={4} xl={4}>
            {/* <p><span className=''>Work by:</span></p> */}
            <img src={SearchIcon} width='20' className='mb-3'/>
{/* ------------------ DATA ------------------ */}
                <p className='columns-flow w-auto'>
                  {data.allMarkdownRemark.group.map(({fieldValue}) => ( 
                            <React.Fragment> 
                                      <Link className='text-capitalize' to={`/tags/${kebabCase(fieldValue)}/`}>
                                        {fieldValue}
                                      </Link>
                            <br/>
                            </React.Fragment>
                            ))}
                </p>
{/* ------------------! DATA ------------------ */}
            </Col>
            <Col xs={6} sm={6} md={{offset: 4, span: 4}} lg={{offset: 4, span: 4}} xl={{offset: 4, span: 4}}>
              {/* <p>Connect:</p> */}
              <img src={EmailIcon} width='30'className='mb-3'/>
              <h6>hello [at] kobybarhad.com</h6>

            </Col>
          </Row>
        </Container>
      </AccordionClose>
    </Card.Body>
  </Accordion.Collapse>
)
}

export default TopAccordion



function AccordionClose({ children, eventKey }) {
  const decoratedOnClick = useAccordionToggle(eventKey)
  return (
    <div
      // type="button"
      onClick={decoratedOnClick}
      className=''
    >
      {children}
    </div>
  );
}
