import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo.svg'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
import vimeo from '../img/social/vimeo.svg'

import {Container, Col, Row, Accordion, Card} from 'react-bootstrap';
import  Clients  from '../components/Clients'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">


        <Container fluid className=''>
          <Accordion  className='fixed-bottom top-shadow white px-0 pt-4 pb-0'>

          <Container fluid className=''>
            <Clients/>
          </Container>

            <Accordion.Collapse eventKey="0">
                                <Card.Body className='mt-5'>
                                  <Container fluid>
                                      <Row>
                                          <div>asda aksjdhas kaskhjasd kjhkasd jhjhsds sd</div>
                                      </Row>
                                    </Container>
                                </Card.Body>
            </Accordion.Collapse>

          </Accordion>
        </Container>


      </footer>
    )
  }
}

export default Footer



