import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import TopNavbar from '../components/TopNavbar'
import envelop from '../img/envelope.svg'

// import './all.sass'
import './custom.scss'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'

// ------------------------
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby'
import {useLocation } from '@reach/router'

// ------------------------

const TemplateWrapper = ({ children }) => {
  let location = useLocation();

  const { title, description } = useSiteMetadata()
  return (

    <div className=''>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />
      </Helmet>

      {/* <TopNavbar/>  */}

      {/* Temporary */}
      <Container>
        <div className='vh-100 d-flex justify-content-center'>
          <div className='text-center align-self-center '>
            <p>While we are working on the website please get in touch:</p>
              <p>
                <img src={envelop} alt="envelop" style={{ maxWidth: '50px' }} />
              </p>
              <p>
                <u>hello (at) kobybarhad.com</u>
              </p>
              {/* <Link  className="navbar-item" to="/tags">          
                Tags
              </Link> */}
            </div>
          </div>
        </Container>

         {/* Contetnt */}
     
        {/* <div>{children}</div>  */}
    
    {/* Footer */}
        {/* {location.pathname === '/blog/' &&
        <Footer /> 
        } */}

    </div>
  )
}

export default TemplateWrapper
