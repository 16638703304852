import React , { useState, useContext } from 'react'
import { Link } from 'gatsby'
import { Button, Container, Row, Col, Accordion, useAccordionToggle, AccordionContext} from 'react-bootstrap'
import {useLocation } from '@reach/router'
import  ModalItem  from '../components/ModalItem'
import  TopAccordion  from '../components/TopAccordion'

const TopNavbar = () => {
  let bio = 'Koby Barhad is a design research consultancy that operates between design, technology and cultural futures.The work spans from brand strategy and user experience design to public exhibitions and editorial design. Barhad client varies from artists such as Marina Abramivic to The Royal Bank of Canada and the Victoria and Albert Museum, UK.'
  let location = useLocation()
  // const [navbarBackroundColor, setNavbarBackroundColor] = useState( 'red' );

  return (
      <Container fluid className=''>
        <Accordion  className='fixed-top' defaultActiveKey="1">

          <TopAccordion navbarBackroundColor={ location.pathname.match(/(?:\b|.*)\/tags\/.+$/) ? 'white-bg' : 'green-bg'}/>
          <nav className={`nav-gradient ${location.pathname.match(/(?:\b|.*)\/tags/) ? 'tagnav' : ''}`}>
            <Container fluid className=''>
                <Row className='d-flex justify-content-between'>
                { 
                location.pathname.match(/(?:\b|.*)\/tags/) 
                ? 
                // Tags Menu
                <TopNavbarNavs leftLinkText='Back' leftLinkClass='' leftTo='/blog/'  />
                  : 
                  // Contacts Menu
                  location.pathname.match(/(?:\b|.*)\/contact/)
                  ?
                  <TopNavbarNavs leftLinkText='Contact' leftLinkClass='' leftTo='/tags/' rightLinkText='Blog' rightLinkClass='' rightTo='/blog/' />
                  :
                  // Blog item 
                  location.pathname.match(/(?:\b|.*)\/blog\/.+$/) 
                  ?
                  null
                  :
                  // Blog Roll 
                  location.pathname.match(/(?:\b|.*)\/blog\/+$/) 
                  &&  
                  <TopNavbarNavs leftLinkText='Koby Barhad' leftTo='/' modal={bio} modalBtnTxt='Bio'/>
                }

                </Row>
            </Container>
            </nav>
        </Accordion>
      </Container>
    )
  }

export default TopNavbar


export const TopNavbarNavs = (props) => {
  return(
    <React.Fragment>
      <Col className='navbar-item'>
        <Link  className={props.leftLinkClass} to={props.leftTo}>{props.leftLinkText}</Link>
      </Col>

      <Col className='text-center '>
        <AcordionButton/>
      </Col>

        {props.modal ?
        <Col className='text-right'>
            <ModalItem 
            contents={props.modal} 
            modalBtnTxt={props.modalBtnTxt}
            title={props.title}
            subtitle={props.subtitle}
            description={props.description}
            shortdescription={props.shortdescription}
            commissioner={props.commissioner}
            colaborators={props.colaborators}
            country={props.country}
            worklinks={props.worklinks}
            year={props.year}
            />
          </Col>
        : 
        <Col className='text-right'>
          <Link className={`${props.rightLinkClass}`} to={props.rightTo}>{props.rightLinkText}</Link>
        </Col>
        }
    </React.Fragment>
  )
}


// Acordion Toggle Button

const AcordionButton = () => {
  return(
    <TagsToggle eventKey="0" ></TagsToggle>
  )
}

function TagsToggle({ children, eventKey, callback }) {
  let location = useLocation()
  const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );
  const isCurrentEventKey = currentEventKey === eventKey;
  return (
    <Button
      type="button"
      onClick={decoratedOnClick}
      className=  {` ${isCurrentEventKey ? 'x-dropdown' : 'arrow-up-dropdown'} `}
    > 
      {children}
    </Button>
  );
}

