import React, {useState} from 'react'
import { Button, Modal, Container, Col, Row} from 'react-bootstrap';


const ModalItem = (props) => {
  const [show, setShow] = useState(false);

    return (
      // <div className=''>
      <React.Fragment>
        <a
        className='navbar-item with-pointer'
        onClick={() => setShow(true)}
        >
        { props.modalBtnTxt ? props.modalBtnTxt : 'no info' }
        </a>
  
        <Modal
          show={show}
          onHide={() => setShow(false)}
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <Container classNam='' > 
              { props.contents !== 'workdetails' ? <h1>{props.contents}</h1> :

                  <React.Fragment>
                  <Row>
                    <Col xs={12} md={8} className='about-index mb-5'>
                      {props.title ? <h1>{props.title}</h1> : null}
                      {props.subtitle ? <p>{props.subtitle}</p> : null}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3} className='about-index'>
                      {props.commissioner ? <p><span className='bold'>Commisioned: </span><br/>{props.commissioner}</p> : null}
                      {props.country ? <p><span className='bold'>Country/s: </span><br/>{props.country}</p> : null}
                      {props.year ? <p><span className='bold'>Year: </span><br/>{props.year}</p> : null}
                      {props.colaborators ? <p><span className='bold'>Colaborator/s: </span><br/>{props.colaborators}</p> : null}
                    </Col>
                    <Col xs={12} md={9} className='about-index'>
                      {props.description ? <p>{props.description}</p> : null}
                      {props.shortdescription ? <p>{props.shortdescription}</p> : null}
                    </Col>
                  </Row>
                  </React.Fragment>
                  }
                      {/* 
                      <Col className='about-intro'>
                        {props.contents ? <p>{props.contents}</p> : null}
                        {props.title ? <p>{props.title}</p> : null}
                        {props.subtitle ? <p>{props.subtitle}</p> : null}
                        {props.description ? <p>{props.description}</p> : null}
                        {props.shortdescription ? <p>{props.shortdescription}</p> : null}
                        {props.commissioner ? <p>{props.commissioner}</p> : null}
                        {props.colaborators ? <p>{props.colaborators}</p> : null}
                        {props.country ? <p>{props.country}</p> : null}
                        {props.worklinks ? <p>{props.worklinks}</p> : null}
                        {props.year ? <p>{props.year}</p> : null}
                      </Col> */}

            </Container>
          </Modal.Body>
        </Modal>
        </React.Fragment>
      // </div>

    );

}
  export default ModalItem
